import { FacetValue } from './types';

export class PipedFacet implements FacetValue {
  constructor(public attribute: string, private values: string[]) { }

  addValues(values: string[]) {
    for (const v of values) {
      if (this.values.indexOf(v) > -1) continue;
      this.values.push(v);
    }
  }

  toggle(value: string): void {
    const index = this.values.indexOf(value);
    if (index > -1) {
      this.values.splice(index, 1);
    } else {
      this.values.push(value);
    }
  }

  isEmpty(): boolean {
    return this.values.length === 0;
  }

  toString(): string {
    return `${this.attribute}:${this.values.join('|')}`;
  }
}
