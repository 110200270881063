import { QueueStorage, LocalStorage } from '../utils';
import { NotificationData } from '../notification';
import { NotificationRunner, Runner } from './notification-runner';

export class NotificationQueue {
  notificationKey = 'notifications';

  constructor(
    private queueStorage: LocalStorage,
    private runner: NotificationRunner
  ) {
    this.run();
  }

  run(): void {
    const queue: NotificationData[] = this.queueStorage.get(this.notificationKey, []);
    if (queue.length === 0) return;

    const next = () => {
      const notificationQueue: NotificationData[] = this.queueStorage.get(this.notificationKey, []);
      notificationQueue.shift();
      this.queueStorage.set(this.notificationKey, notificationQueue);
      this.run();
    };

    const retry = () => { this.run(); };
    this.runner.run(queue[0], next, retry);
  }

  add(item: NotificationData): void {
    const queue: NotificationData[] = this.queueStorage.get(this.notificationKey, []);
    queue.push(item);

    this.queueStorage.set(this.notificationKey, queue);
    if (queue.length === 1) this.run();
  }

  get(): NotificationData[] {
    return this.queueStorage.get(this.notificationKey, []);
  }
}

export const Queue = new NotificationQueue(QueueStorage, Runner);
