import { Filter } from './types';

export class NotFilter implements Filter {
  constructor(private filter: Filter) {
    if (!filter) throw new Error('Filter must be defined');
  }

  toString(): string {
    return `NOT ${this.filter.toString()}`;
  }
}
