import { FacetValue } from './types';

export class RangeFacet implements FacetValue {
  constructor(public attribute: string, private min: number, private max: number) { }

  update(min: number, max: number): void {
    this.min = min;
    this.max = max;
  }

  toString(): string {
    return `${this.attribute}:[${this.min},${this.max}]`;
  }
}
