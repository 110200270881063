import { SearchAttributeType, SearchAttributeGroup } from './search-attribute-group';

export class SearchAttributeBuilder {
  product = new SearchAttributeGroup(SearchAttributeType.Product);
  category = new SearchAttributeGroup(SearchAttributeType.Category);

  toString() {
    if (this.product.isEmpty() && this.category.isEmpty()) return '';
    return [this.product, this.category]
      .filter(sa => !sa.isEmpty())
      .map(sa => sa.toString())
      .join(', ');
  }
}
