import { ResponseData } from './types';

export const createAbortableRequestPromise = (method: string, url: string, body?: string): Promise<ResponseData> => {
  const xhr = new XMLHttpRequest();
  const promise = new Promise<ResponseData>((resolve, reject) => {
    xhr.open(method, url, true);
    if (body) {
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (Math.floor(xhr.status / 100) === 2) {
          const successData = xhr.responseText ? JSON.parse(xhr.responseText) : null;
          return resolve({ status: xhr.status, response: successData });
        }
        if (xhr.status === 0) {
          return reject({ status: xhr.status, response: 'Aborted' });
        }
        return reject({ status: xhr.status, response: xhr.responseText });
      }
    };
    xhr.send(body);
  });

  promise.abort = () => xhr.abort();

  return promise;
};

export function stringify(args: any): string {
  if (!args) return '';
  return Object.getOwnPropertyNames(args).map(arg => `${arg}=${encodeURIComponent(args[arg])}`).join('&');
}
