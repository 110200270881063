import { Filter, escapeValue, escapeAttributeArray } from './';

export class AttributeFilter implements Filter {
  attributes: string[];
  constructor(attributes: string[] | string, private value: string, private incremental: boolean) {
    if (!attributes || attributes.length === 0) {
      throw new Error('Attributes must be defined and have at least one attribute');
    }
    if (value == null || value === undefined) {
      throw new Error('Value can\'t be null or undefined');
    }
    this.value = escapeValue(value);
    this.attributes = escapeAttributeArray(!Array.isArray(attributes) ? [attributes] : attributes);
  }

  toString(): string {
    const n = this.attributes.join(',');
    return `${n}${this.incremental ? '*' : ''}:'${this.value}'`;
  }
}
