import { Filter } from './';

export class AndFilter implements Filter {
  constructor(private filters: Filter[]) {
    if (!filters || filters.length < 1) {
      throw new Error('Filters must be defined and have more than one filter');
    }
  }

  toString(): string {
    return `(${this.filters.map((filter: Filter) => filter.toString()).join(' AND ')})`;
  }
}
