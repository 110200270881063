/**
 * A User Agent help function
 *
 * @function isBot: checks if user agent is in a list of blacklisted user agents
 */
export class UserAgent {
  agent: string;
  DISALLOWED_BOT_LIST: string[] = [
    'Googlebot',
    'Google',
    'Bingbot',
    'Slurp',
    'Baiduspider',
    'YandexBot',
    'Apptus',
    'Sogou',
    'Exabot',
    'Facebot',
    'facebookexternalhit',
    'ia_archiver',
    // generic
    'bot',
    'crawl',
    'scrape'
  ];
  regex = new RegExp(`(${this.DISALLOWED_BOT_LIST.join('|')})`, 'i');

  constructor(navigator: Navigator) {
    this.agent = navigator.userAgent;
  }

  isBot(): boolean {
    return this.regex.test(this.agent);
  }
}
