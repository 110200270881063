export class LocalStorage {
  constructor(private storageName: string, private localStorage: Storage) { }

  info(): { [key: string]: any } {
    let info = {};
    try {
      info = JSON.parse(this.localStorage.getItem(this.storageName) || '{}') as { [key: string]: any };
    } catch (e) {
      console.warn('Was unable to parse storage ', this.storageName);
    }

    return info;
  }

  get(key: string, defaultValue: any): any {
    const storedObject = this.info();
    const value = storedObject[key];
    if (!value) {
      this.set(key, defaultValue);
      return defaultValue;
    }

    return value;
  }

  set(key: string, value: any): void {
    const storedObject = this.info();
    storedObject[key] = value;
    this.localStorage.setItem(this.storageName, JSON.stringify(storedObject));
  }
}

export const QueueStorage = new LocalStorage('apptus.queue', localStorage);
