export { FilterBuilder } from './filter-builder';
export { Filter } from './types';
export function escapeAttributeArray(values: string[]): string[] {
  return values.map(val => (escapeAttribute(val)));
}

function escapeAttribute(value: string): string {
  const specials = ['\\', ':', ','];
  const regex = new RegExp(`[${specials.join('\\')}]`, 'g');
  return value.replace(regex, '\\$&');
}

export function escapeValue(value: string): string {
  const specials = ['\\', '\''];
  const regex = new RegExp(`[${specials.join('\\')}]`, 'g');
  return value.replace(regex, '\\$&');
}
