import { Filter } from './';

export class OrFilter implements Filter {
  constructor(private filters: Filter[]) {
    if (!filters || filters.length < 1) throw new Error('Filters must be defined and have at least one filter');
  }

  toString(): string {
    return `(${this.filters.map((filter: Filter) => filter.toString()).join(' OR ')})`;
  }
}
