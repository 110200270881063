import { PaymentSettings, PaymentLine } from './types';
import { validate, NotificationQueue } from '../';
import { BaseNotificationData } from '../types';

export class Payment {
  lines: PaymentLine[] = [];
  constructor(private settings: PaymentSettings, private queue: NotificationQueue) { }

  add(line: PaymentLine): Payment {
    validate({ productKey: line.productKey, variantKey: line.variantKey } as BaseNotificationData);

    if (line.quantity == null || isNaN(line.quantity)) {
      throw new Error('Quantity must be specified');
    }

    line.quantity = Number(line.quantity.toFixed(0));

    if (line.quantity <= 0) {
      throw new Error('Quantity must be greater than 0');
    }

    if (line.quantity > 2147483647) {
      throw new Error('Quantity must not be larger than 2147483647');
    }

    if (line.unitSellingPrice == null || isNaN(line.unitSellingPrice)) {
      throw new Error('UnitSellingPrice must be specified');
    }

    if (line.unitSellingPrice <= 0) {
      throw new Error('UnitSellingPrice must be greater than 0');
    }

    this.lines.push(line);
    return this;
  }

  send(): void {
    if (this.lines.length === 0) {
      throw new Error('You can\'t submit a payment without lines. Please use the .add() method');
    }

    this.queue.add({
      ...this.settings,
      lines: this.lines
    });
  }
}
