import { PipedFacet } from './piped-facet';
import { RangeFacet } from './range-facet';
import { FacetValue } from './types';

const ATTRIBUTE_NAME_ERROR = 'Attribute name must be at least one character';

export class Facet {
  facets: FacetValue[] = [];

  add(attribute: string, values: string[] | string): Facet {
    if (!attribute) throw new Error(ATTRIBUTE_NAME_ERROR);
    attribute = this.escape(attribute);

    if (!values || Array.isArray(values) && values.some((val: string) => !val)) {
      throw new Error('Undefined and null values are not allowed');
    }

    const v: string[] = !Array.isArray(values) ? this.escapeArray([values]) : this.escapeArray(values);

    const index = this.getIndex(attribute);
    if (index > -1) {
      if (!(this.facets[index] instanceof PipedFacet)) {
        throw new Error('Cannot combine piped list and ranges of the same attribute');
      }
      (this.facets[index] as PipedFacet).addValues(v);
    } else {
      this.facets.push(new PipedFacet(attribute, v));
    }
    return this;
  }

  addRange(attribute: string, from: number, to: number): Facet {
    if (!attribute) throw new Error(ATTRIBUTE_NAME_ERROR);

    if (isNaN(parseInt(from.toString(), 10)) || isNaN(parseInt(to.toString(), 10))) {
      throw new Error('From and to must be numbers');
    }

    const index = this.getIndex(attribute);
    if (index > -1) {
      if (!(this.facets[index] instanceof RangeFacet)) {
        throw new Error('Cannot combine piped list and ranges of the same attribute');
      }
      (this.facets[index] as RangeFacet).update(from, to);
    } else {
      this.facets.push(new RangeFacet(attribute, from, to));
    }
    return this;
  }

  toggle(attribute: string, value: string): Facet {
    const index = this.getIndex(attribute);
    if (index === -1) {
      this.facets.push(new PipedFacet(attribute, this.escapeArray([value])));
    } else {
      if (!(this.facets[index] instanceof PipedFacet)) {
        throw new Error('Cannot toggle a range');
      }

      const f = this.facets[index] as PipedFacet;
      f.toggle(this.escapeArray([value])[0]);

      // if toggling removed the value, remove the facet
      if (f.isEmpty()) {
        this.facets.splice(index, 1);
      }
    }
    return this;
  }

  remove(attribute: string): Facet {
    const index = this.getIndex(attribute);
    if (index > -1) { this.facets.splice(index, 1); }
    return this;
  }

  toString(): string {
    return this.facets.map((facet: FacetValue) => facet.toString()).join(',');
  }

  private escapeArray(values: string[]): string[] {
    const specials = ['\\', ':', '|', ','];
    const regex = new RegExp(`[${specials.join('\\')}]`, 'g');
    return values.map((val: string) => val.replace(regex, '\\$&'));
  }

  private escape(value: string): string {
    const specials = ['\\', ':', '|', ','];
    const regex = new RegExp(`[${specials.join('\\')}]`, 'g');
    return value.replace(regex, '\\$&');
  }

  private getIndex(attribute: string): number {
    return this.facets.map((facet: FacetValue) => facet.attribute).indexOf(attribute);
  }
}
