import { Filter } from './types';
import { RangeFilter } from './range-filter';
import { AttributeFilter } from './attribute-filter';
import { AndFilter } from './and-filter';
import { OrFilter } from './or-filter';
import { NotFilter } from './not-filter';

export class FilterBuilder {
  attribute(attributes: string[] | string, value: string, incremental = false): Filter {
    return new AttributeFilter(attributes, value, incremental);
  }

  range(attributes: string[] | string, from: number, to: number, includeFrom = true, includeTo = true): Filter {
    return new RangeFilter(attributes, from, to, includeFrom, includeTo);
  }

  and(filters: Filter[]): Filter {
    return new AndFilter(filters);
  }

  or(filters: Filter[]): Filter {
    return new OrFilter(filters);
  }

  not(filter: Filter): Filter {
    return new NotFilter(filter);
  }
}
