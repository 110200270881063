import { BaseNotificationData } from './types';

export function validate(data?: BaseNotificationData) {
  if (data === null || data === undefined || (!data.productKey && !data.variantKey)) {
    throw new Error('ProductKey and VariantKey can\'t both be null, at least one must be specified');
  }
}

export function validateTicket(ticket?: string) {
  if (ticket === null || ticket === undefined) {
    throw new Error('Ticket can\'t be null or undefined');
  }
}
