import { WebApiSettings } from './types';
import { ResponseData } from '../abortable-promise';
import { Panel } from '../panel';
import { UserAgent } from '../utils';
import { Notification, Queue } from '../notification';
import { CookieHandler, CookieKey } from '../cookie';
import { Facet } from '../facet';
import { FilterBuilder } from '../filter-builder';
import { SearchAttributeBuilder } from '../search-attribute-builder';

export class WebAPI {
  url: string;
  market: string;
  notify: Notification;
  agent: UserAgent;
  cookies: CookieHandler;
  utils = {
    Facet,
    FilterBuilder,
    SearchAttributeBuilder
  };

  constructor(settings: WebApiSettings) {
    if (!(settings.url || settings.clusterId)) {
      throw new Error('Specify a url in the settings object');
    }

    if (!settings.market) {
      throw new Error('Market must be specified');
    }

    this.url = settings.url ? settings.url : `https://${settings.clusterId}.api.esales.apptus.cloud/api/v2`;
    this.cookies = new CookieHandler(window.document, settings.domain);

    const ending = '/api/v2';
    const endIndex = this.url.length - ending.length;

    if (this.url.lastIndexOf(ending) !== endIndex) {
        this.url  += '/api/v2';
    }

    this.market = settings.market;
    this.notify = new Notification(this.url, this.market, Queue, this.cookies);
    this.agent = new UserAgent(window.navigator);
  }

  panel(path: string, args: any): Promise<ResponseData> {
    if (!args) args = {};
    if (this.agent.isBot()) args.notify = false;

    const panel = new Panel({
      url: this.url,
      path,
      args: { ...args },
      market: this.market,
      sessionKey: this.cookies.getOrCreate(CookieKey.SessionKey),
      customerKey: this.cookies.getOrCreate(CookieKey.CustomerKey)
    });

    return panel.createPanelRequest();
  }
}
