const VALIDATE_ATTRIBUTE_ERROR = 'attribute can\'t be null, undefined, or empty string';

export class SearchAttributeGroup {
  attributes: string[] = [];
  constructor(public type: SearchAttributeType) { }

  add(attribute: string | string[]) {
    const attributes = Array.isArray(attribute) ? attribute : [attribute];

    for (const attr of attributes) {
      if (typeof attr === 'string' && attr.length > 0) {
        if (this.attributes.indexOf(attr) > -1) continue;
        this.attributes.push(attr);
      } else {
        throw new Error(VALIDATE_ATTRIBUTE_ERROR);
      }
    }
  }

  remove(attribute: string | string[]) {
    const attributes = Array.isArray(attribute) ? attribute : [attribute];

    for (const attr of attributes) {
      if (typeof attr === 'string' && attr.length > 0) {
        const idx = this.attributes.indexOf(attr);
        if (idx === -1) continue;
        this.attributes.splice(idx, 1);
      } else {
        throw new Error(VALIDATE_ATTRIBUTE_ERROR);
      }
    }
  }

  isEmpty() {
    return this.attributes.length === 0;
  }

  toString() {
    return this.isEmpty() ? '' : `${this.type}[${this.attributes.join(', ')}]`;
  }
}

export enum SearchAttributeType {
  Product = 'product',
  Category = 'category'
}
