import { NotificationData } from './types';
import { createAbortableRequestPromise, ResponseData, stringify } from '../abortable-promise';

export class NotificationRunner {
  delay = 500;
  constructor() { }

  run(notification: NotificationData, next: () => void, retry: () => void) {
    const { url, market, sessionKey, customerKey, ...args } = notification;
    const query = stringify({
      'esales.market': market,
      'esales.sessionKey': sessionKey,
      'esales.customerKey': customerKey
    });

    createAbortableRequestPromise('POST', `${url}?${query}`, JSON.stringify(args))
      .then(() => next())
      .catch((err: ResponseData) => {
        if (err.status >= 400 && err.status < 500) {
          next();
        } else {
          setTimeout(retry, this.delay);
          this.delay *= 2;
        }
      });
  }
}

export const Runner = new NotificationRunner();
