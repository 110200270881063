export class CookieValidator {
    constructor(private document: Document) { }

    /**
     * Verify that the specified domain is OK to use.
     *
     * See more information at:
     * https://tools.ietf.org/html/rfc6265#section-5.1.3
     * https://tools.ietf.org/html/rfc6265#section-4.1.2.3
     *
     * @param domain the domain to verify
     */
    isValidDomain(domain?: string): boolean {
        if (domain == undefined) {
            return true;
        }

        if (/\.{2,}/.test(domain)) {
            return false;
        }

        if (domain.indexOf('.') === 0) {
            domain = domain.substring(1);
        }

        if (/[^a-z0-9.-]/.test(domain)) {
            return false;
        }

        const hostname = this.document.location.hostname;

        if (/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(domain)) {
            return false;
        }

        // endsWith
        return hostname.indexOf(domain, hostname.length - domain.length) !== -1;
    }

    /**
     * Check that the uuid is a valid uuid v4.
     * @param uuid uuid to validate
     */
    isValidIdentifier(uuid: string): boolean {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);
    }
}
