import { CookieKey } from "./cookie-handler";

export class CookieStorage {
  DELETE_DATE: string = new Date(1000).toUTCString();

  constructor(private namespace: string, private document: Document) { }

  /**
   * Create a cookie based on the arguments.
   *
   * @param key cookie key.
   * @param value cookie value.
   * @param expires expiration or undefined for session cookies.
   * @param cookieDomain which domain to create the cookie for.
   */
  set(key: CookieKey, value: string, expires?: string, cookieDomain?: string): void {
    const expiration = expires ? ` expires=${expires};` : '';
    const secure = this.document.location.protocol.indexOf('https') > -1 ? ' Secure;' : '';
    const domain = cookieDomain ? ` domain=${cookieDomain};` : '';

    this.document.cookie = `${this.namespace}.${key}=${value}; path=/;${domain}${expiration}${secure}`;
  }

  /**
   * Return the value of the cookie if found, otherwise null.
   *
   * @param key which key to look for.
   */
  get(key: CookieKey): string | null {
    const cookies = this.document.cookie.split(/\s*;\s*/);
    for (const cookie of cookies) {
      const keyVal = cookie.split('=');
      if (keyVal[0] === `${this.namespace}.${key}`) {
        return keyVal[1];
      }
    }
    return null;
  }

  /**
   * Return number of instance of cookies that exists with the same name.
   * Cookies use name, path and domain as identifier.
   *
   * @param key cookie key.
   */
  numberOfInstances(key: CookieKey): number {
    let count = 0;
    const cookies = this.document.cookie.split(/\s*;\s*/);
    for (const cookie of cookies) {
      const keyVal = cookie.split('=');
      if (keyVal[0] === `${this.namespace}.${key}`) {
        count++;
      }
    }
    return count;
  }
  /**
   * Try to delete a cookie based on it's key and domain.
   * Undefined domain is the equavalent of creating a cookie without `domain=example.com;`.
   * 
   * @param key the cookie key.
   * @param domain the domain for the cookie.
   */
  delete(key: CookieKey, domain?: string): void {
    this.set(key, '', this.DELETE_DATE, domain);
  }
}
