import { NotificationQueue } from './notification-queue';
import { CookieHandler, CookieKey } from '../cookie';
import { NotificationData, NonEsalesNotificationData, FavoriteNotificationData } from './types';
import { Payment, PaymentSettings } from './payment';
import { validate, validateTicket } from './notification-validation';

export class Notification {
  constructor(
    private baseURL: string,
    private market: string,
    private queue: NotificationQueue,
    private cookies: CookieHandler) { }

  private generateId(): string { return Math.random().toString(36); }

  click(ticket: string): void {
    validateTicket(ticket);
    this.queue.add({
      id: this.generateId(),
      url: getUrl(this.baseURL, 'click'),
      sessionKey: this.cookies.getOrCreate(CookieKey.SessionKey),
      customerKey: this.cookies.getOrCreate(CookieKey.CustomerKey),
      market: this.market,
      ticket
    } as NotificationData);
  }

  nonEsalesClick(data: NonEsalesNotificationData): void {
    validate(data);
    this.queue.add({
      id: this.generateId(),
      url: getUrl(this.baseURL, 'non-esales-click'),
      sessionKey: this.cookies.getOrCreate(CookieKey.SessionKey),
      customerKey: this.cookies.getOrCreate(CookieKey.CustomerKey),
      market: this.market,
      productKey: data.productKey,
      variantKey: data.variantKey
    } as NotificationData);
  }

  addToCart(ticket: string): void {
    validateTicket(ticket);
    this.queue.add({
      id: this.generateId(),
      url: getUrl(this.baseURL, 'adding-to-cart'),
      sessionKey: this.cookies.getOrCreate(CookieKey.SessionKey),
      customerKey: this.cookies.getOrCreate(CookieKey.CustomerKey),
      market: this.market,
      ticket
    } as NotificationData);
  }

  nonEsalesAddToCart(data: NonEsalesNotificationData): void {
    validate(data);
    this.queue.add({
      id: this.generateId(),
      url: getUrl(this.baseURL, 'non-esales-adding-to-cart'),
      sessionKey: this.cookies.getOrCreate(CookieKey.SessionKey),
      customerKey: this.cookies.getOrCreate(CookieKey.CustomerKey),
      market: this.market,
      productKey: data.productKey,
      variantKey: data.variantKey
    } as NotificationData);
  }

  payment(): Payment {
    return new Payment({
      id: this.generateId(),
      url: getUrl(this.baseURL, 'payment'),
      sessionKey: this.cookies.getOrCreate(CookieKey.SessionKey),
      customerKey: this.cookies.getOrCreate(CookieKey.CustomerKey),
      market: this.market
    } as PaymentSettings,
      this.queue);
  }

  addFavorite(data: FavoriteNotificationData): void {
    validate(data);
    this.queue.add({
      id: this.generateId(),
      url: getUrl(this.baseURL, 'favorite/add'),
      sessionKey: this.cookies.getOrCreate(CookieKey.SessionKey),
      customerKey: this.cookies.getOrCreate(CookieKey.CustomerKey),
      market: this.market,
      productKey: data.productKey,
      variantKey: data.variantKey,
    } as NotificationData);
  }

  removeFavorite(data: FavoriteNotificationData): void {
    validate(data);
    this.queue.add({
      id: this.generateId(),
      url: getUrl(this.baseURL, 'favorite/remove'),
      sessionKey: this.cookies.getOrCreate(CookieKey.SessionKey),
      customerKey: this.cookies.getOrCreate(CookieKey.CustomerKey),
      market: this.market,
      productKey: data.productKey,
      variantKey: data.variantKey,
    } as NotificationData);
  }

  end(): void {
    this.queue.add({
      id: this.generateId(),
      url: getUrl(this.baseURL, 'end'),
      sessionKey: this.cookies.getOrCreate(CookieKey.SessionKey),
      customerKey: this.cookies.getOrCreate(CookieKey.CustomerKey),
      market: this.market
    } as NotificationData);

    this.cookies.set(CookieKey.SessionKey, this.cookies.uuid());
    this.cookies.set(CookieKey.CustomerKey, this.cookies.uuid());
  }
}

const getUrl = (baseURL: string, type: string): string => `${baseURL}/notifications/${type}`;
