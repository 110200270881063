import { Filter, escapeAttributeArray } from './';

export class RangeFilter implements Filter {
  attributes: string[];
  constructor(
    attributes: string[] | string,
    private from: number,
    private to: number,
    private includeFrom = true,
    private includeTo = true
  ) {
    if (!attributes || attributes.length === 0) {
      throw new Error('Attributes must be defined and be atleast one');
    }
    if (from == null || from === undefined || to == null || to === undefined) {
      throw new Error('From and to can\'t be null or undefined');
    }

    if (isNaN(parseInt(from.toString(), 10)) || isNaN(parseInt(to.toString(), 10))) {
      throw new Error('From and to must be numbers');
    }

    this.attributes = escapeAttributeArray(!Array.isArray(attributes) ? [attributes] : attributes);
  }

  toString(): string {
    const n = this.attributes.join(',');
    const f = `${this.includeFrom ? '[' : '('}'${this.from}'`;
    const t = `'${this.to}'${this.includeTo ? ']' : ')'}`;
    return `${n}:${f},${t}`;
  }
}
