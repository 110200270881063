import { ResponseData, createAbortableRequestPromise, stringify } from '../abortable-promise';
import { PanelSettings } from './types';

export class Panel {
  constructor(private settings: PanelSettings) { }

  createPanelRequest(): Promise<ResponseData> {
    const query = stringify({
      ...this.settings.args,
      'esales.market': this.settings.market,
      'esales.customerKey': this.settings.customerKey,
      'esales.sessionKey': this.settings.sessionKey
    });

    return createAbortableRequestPromise('GET', `${this.settings.url}/panels${this.settings.path}?${query}`);
  }
}
