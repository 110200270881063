import { v4 as uuid } from 'uuid';
import { CookieStorage } from './cookie-storage';
import { CookieValidator } from './cookie-validator';

export class CookieHandler {
  storage: CookieStorage;
  validator: CookieValidator;
  namespace = 'apptus';
  uuid = uuid;

  constructor(private document: Document, private domain?: string) {
    this.validator = new CookieValidator(this.document);
    this.storage = new CookieStorage(this.namespace, this.document);

    if (!this.validator.isValidDomain(this.domain)) {
      // tslint:disable-next-line:max-line-length
      throw new Error('Invalid cookie domain specified. Make sure the domain is: only lowercase, a substring of current domain and not an ip.');
    }

    // initiate keys
    const stashedCustomerKey = this.getOrCreate(CookieKey.CustomerKey);
    this.getOrCreate(CookieKey.SessionKey);

    if (this.domain || this.storage.numberOfInstances(CookieKey.CustomerKey) > 1) {
      // only try to update domain of cookies if they are set.
      this.deleteDomainCookies(CookieKey.CustomerKey);
      this.set(CookieKey.CustomerKey, stashedCustomerKey);
    }
  }

  /**
   * Remove cookie with the same key, same path but with all possible domains.
   *
   * Will try and remove cookies for each domain and also prefix domain with a dot (.).
   *
   * It'll also delete the cookie WITHOUT domain specified (undefined), this is
   * different from specifying the exact domain cookie.s
   *
   * null in this case means the current domain.
   *
   * If the hostname is www.example.com, then the domains that are handled are:
   * [.www.example.com, www.example.com, .example.com, example.com, undefined].
   *
   * @param key Which cookie key to remove duplicates for
   */
  private deleteDomainCookies(key: CookieKey) {
    const parts = this.document.location.hostname.split('.');
    const possibleDomains = [];
    for (let i = 0; i <= parts.length - 2; i++) {
      const domain = parts.slice(i).join('.');
      possibleDomains.push(domain);
      possibleDomains.push(domain.substring(0, 1) !== '.' ? '.' + domain : domain.substring(1));
    }
    possibleDomains.push(undefined);

    for (const domain of possibleDomains) {
      this.storage.delete(key, domain);
    }
  }

  private oneYearFromNow(): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date.toUTCString();
  }

  getOrCreate(key: CookieKey): string {
    let cookie = this.storage.get(key);
    if (cookie == null) {
      cookie = this.uuid();
      this.storage.set(key, cookie, this.getExpiration(key), this.domain);
      return cookie;
    }

    if (!this.validator.isValidIdentifier(cookie)) throw new Error(`Invalid ${key} specified in the cookie`);

    this.storage.set(key, cookie, this.getExpiration(key), this.domain);
    return cookie;
  }

  set(key: CookieKey, value: string): string {
    if (!this.validator.isValidIdentifier(value)) throw new Error(`Invalid value ${value} specified for ${key}`);
    this.storage.set(key, value, this.getExpiration(key), this.domain);
    return value;
  }

  private getExpiration(key: CookieKey): string | undefined {
    return key === CookieKey.CustomerKey ? this.oneYearFromNow() : undefined;
  }
}

export enum CookieKey {
  CustomerKey = 'customerKey',
  SessionKey = 'sessionKey'
}
