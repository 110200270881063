// tslint:disable:no-import-side-effect
import 'promise/polyfill';
import { WebApiSettings, WebAPI } from './web-api';
import { PACKAGE_VERSION } from './types';

export function esalesAPI(settings: WebApiSettings): WebAPI {
  return new WebAPI(settings);
}

export function esalesAPIVersion(): string {
  return PACKAGE_VERSION;
}
